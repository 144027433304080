<template>
  <v-menu ref="menu" v-model="menu" :close-on-content-click="false" transition="scale-transition" offset-y min-width="290px">
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="dateFormatted"
        :value="formatDateL(date)"
        :label="label"
        prepend-icon="event"
        readonly
        v-on="on"
        class="is_datepicker"
        :rules="required ? formRules.textRules : []"
        :clearable="clearable"
        @click:clear="$emit('change', '')"
      />
    </template>
    <v-date-picker
      ref="picker"
      v-model="date"
      :max="type_birthdate ? ageRestrinction() : max"
      :min="type_birthdate ? '' : min"
      :readonly="readonly"
      first-day-of-week="1"
      :no-title="no_title"
      @change="save"
    />
  </v-menu>
</template>

<script>
import FormRulesMixin from "@/mixins/FormRulesMixin";
import FormatDateMixin from "@/mixins/FormatDateMixin";

export default {
  mixins: [FormRulesMixin, FormatDateMixin],
  data() {
    return {
      date: null,
      dateFormatted: null,
      menu: false
    };
  },
  watch: {
    menu(val) {
      if (this.type_birthdate) {
        val && setTimeout(() => (this.$refs.picker.activePicker = "YEAR"));
      }
    },
    parent_model() {
      this.parent_model != "" ? (this.date = this.formatDate(this.parent_model)) : (this.date = null);
    },
    date() {
      this.dateFormatted = this.formatDateL(this.date);
    }
  },
  props: {
    label: {
      type: String,
      required: true
    },
    type_birthdate: {
      type: Boolean,
      required: false,
      default: false
    },
    required: {
      type: Boolean,
      required: false,
      default: false
    },
    readonly: {
      type: Boolean,
      required: false,
      default: false
    },
    parent_model: {
      type: String,
      required: false,
      default: ""
    },
    min: {
      type: String,
      required: false,
      default: ""
    },
    max: {
      type: String,
      required: false,
      default: ""
    },
    clearable: {
      type: Boolean,
      required: false,
      default: true
    },
    no_title: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  created() {
    if (this.parent_model) this.date = this.formatDate(this.parent_model);
  },
  methods: {
    save() {
      this.menu = false;
      this.$emit("change", this.date);
    }
  }
};
</script>
